<template>
  <div class="grid">
		<div class="col-12">
            <div class="card">
                <Toast/>
                <Error :errors="errors"/>

                <Fieldset legend="Tambah Baru" :toggleable="true" :collapsed="true" class="mb-3" v-if="level != '3'">
                    <AddNew @submit="getDataTable()" ref="create"/>
                </Fieldset>

                <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="number" @sort="onSort($event)" :resizableColumns="true" columnResizeMode="fit">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <div>
                                <!-- <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('xlsx')" />
                                <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('csv')" /> -->
                            </div>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="search" placeholder="Search..." @keyup.enter="getDataTable" />
                            </span>
                        </div>
                    </template>
                    <Column field="number" header="No." :style="{width:'20px'}" >
                        <template #body="slotProps">
                        {{ slotProps.data.number }}
                        </template>
                    </Column>
                    <Column field="kode_agent" header="Kode Agent/Username">
                        <template #body="slotProps">
                            {{slotProps.data.kode_agent}}
                        </template>
                    </Column>
                    <Column field="userweb_nama" header="Nama">
                        <template #body="slotProps">
                            {{slotProps.data.userweb_nama}}
                        </template>
                    </Column>
                    <Column field="status" header="Status">
                        <template #body="slotProps">
                            <Button icon="pi pi-times" class="p-button-rounded p-button-danger" v-if="slotProps.data.status_ == 0"/>
                            <Button icon="pi pi-check" class="p-button-rounded p-button-success" v-else/>
                        </template>
                    </Column>
					<Column header="Action" headerStyle="min-width:10rem;">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded mr-2" @click="editItem(slotProps.data)" v-if="slotProps.data.isUpdate == 1"/>
						</template>
					</Column>
                    <template #empty>
                        No data found.
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template>
                    <template #footer>
                        In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
                    </template>
                </DataTable>

                <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]">
                    <template>
                        {{totalItemsCount}}
                    </template>
                </Paginator>

                <Edit @submit="getDataTable()" :item="item" ref="edit"/>
            </div>
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import { numberingDatatable } from '../../utils/helpers';
import AddNew from '../../components/master/agent/AddNew.vue';
import Edit from '../../components/master/agent/Edit.vue';
import Error from '../../components/Error.vue';

export default {
    components: {
		'AddNew': AddNew,
		'Edit': Edit,
		'Error': Error,
	},
    data() {
        return {
            // loading
            loading: false,
            // loadingExcel: false,
            // loadingCsv: false,

            // edit,delete
            item: {},

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,

            level: null,
        }
    },
    mounted() {
        this.getDataTable();
        this.$store.commit('setErrors', {});
        setTimeout(() => {
            this.level = this.user.level_id;
        }, 250);
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        }
    },
    computed:{
        ...mapGetters(['errors']),
		...mapGetters("auth", ["user"]),
    },
    methods: {
        formatDate(value){
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        // EDIT
		editItem(item) {
			this.item = item;
            
            this.$refs.edit.editItemDialog = true;
		},
        // DATATABLE
        getDataTable(){
          
            this.loading=true;
            // this.$refs.filter.loading = true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/agent',
                params: {
                    "search" : this.search,
                    "per_page" : this.rows,
                    "page" : this.page,
                    "order_by" : this.field,
                    "sort_by" : this.sort,
                }
            })
            .then(res => {
                this.data = res.data.data.data;
                this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
                this.totalItemsCount = res.data.data.total;
                this.rows = parseInt(res.data.data.per_page);
                this.loading=false;
                // this.$refs.filter.loading = false;
            })
            .catch((err) => {
                console.log(err);
                this.loading=false;
                // this.$refs.filter.loading = false;
            });
         
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
        // EXPORT
        // exportExcelCSV(ext){

        //     if(ext == 'xlsx'){
        //         this.loadingExcel=true;
        //     }else if(ext == 'csv'){
        //         this.loadingCsv=true;
        //     }

        //     this.axios({
        //     method: 'GET',
        //     url: process.env.VUE_APP_ROOT_API + 'web/agent/export',
        //     responseType: 'blob',
        //     params: {
        //         "ext" : ext,
        //     }
        //     })
        //     .then(response => {
        //         let fileUrl = window.URL.createObjectURL(response.data);
        //         let fileLink = document.createElement('a');

        //         fileLink.href = fileUrl;

        //         fileLink.setAttribute('download', 'Agent Export.' + ext);
                
        //         document.body.appendChild(fileLink)

        //         fileLink.click();
                
        //         if(ext == 'xlsx'){
        //             this.loadingExcel=false;
        //         }else if(ext == 'csv'){
        //             this.loadingCsv=false;
        //         }

        //     })
        //     .catch((err) => {
        //         console.log(err);
                
        //         if(ext == 'xlsx'){
        //             this.loadingExcel=false;
        //         }else if(ext == 'csv'){
        //             this.loadingCsv=false;
        //         }
        //     });
        // },
    }
}
</script>