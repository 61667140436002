<!-- eslint-disable vue/no-mutating-props -->
<template>
    <Dialog v-model:visible="editItemDialog" :style="{width: '450px'}" header="Data Detail" :modal="true" class="p-fluid">
        <div class="field">
            <label>Kode Agent/Username</label>
            <InputText id="kode_agent" :value="item.kode_agent" required="true" autofocus :class="{ 'p-invalid': errorEdit.kode_agent }" disabled />
            <small class="p-invalid" style="color: red" v-if="errorEdit.kode_agent">{{errorEdit.kode_agent[0]}}</small>
        </div>
        <!-- <div class="field">
            <label>Kode Agent/Username</label>
            <InputText id="kode_agent" v-model="item.kode_agent" required="true" autofocus :class="{ 'p-invalid': errorEdit.kode_agent }" />
            <small class="p-invalid" style="color: red" v-if="errorEdit.kode_agent">{{errorEdit.kode_agent[0]}}</small>
        </div> -->
        <div class="field">
            <label>Nama</label>
            <InputText id="userweb_nama" v-model="item.userweb_nama" required="true" autofocus :class="{ 'p-invalid': errorEdit.nama }" />
            <small class="p-invalid" style="color: red" v-if="errorEdit.nama">{{errorEdit.nama[0]}}</small>
        </div>
        <div class="field">
            <label>Status</label>
            <Dropdown v-model="item.status_" required="true" :options="dataDropdownStatus" optionLabel="name" optionValue="code" :class="{ 'p-invalid': errorEdit.status_ }" placeholder="" />
            <small class="p-invalid" style="color: red" v-if="errorEdit.status_">{{errorEdit.status_[0]}}</small>
        </div>
        <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button label="Update" icon="pi pi-check" class="p-button-text" @click="updateItem" :loading="loading"/>
        </template>
    </Dialog>
</template>


<script>

import { mapGetters } from "vuex";

export default {
    props: [
		"item",
    ],
    data() {
        return {
            // loading
            loading: false,

            // dataDropdown
            dataDropdownStatus: [
                {name: 'Aktif', code: 1},
                {name: 'Nonaktif', code: 0},
            ],

            // edit
            editItemDialog: false,
        }
    },
    mounted() {
        this.$store.commit('setErrorEdit', {});
    },
    watch: {
        editItemDialog: {
            handler() {
                setTimeout(() => {
                    if(this.editItemDialog == true){
                        // this.searchDropdownProvinsi('', 'edit', this.item.provinsi_name);
                        // this.searchDropdownKabupaten('', 'edit', this.item.kabupaten_name);
                        // this.old_kabupaten_id = this.item.kabupaten_id;
                    }
                }, 500);
            },
        }
    },
    computed:{
        ...mapGetters(['errorEdit']),
    },
    methods: {
        // EDIT
		hideDialog() {
			this.editItemDialog = false;
		},
		updateItem() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/agent/update',
                data: {
                    "userweb_id" : this.item.userweb_id,
                    "nama" : this.item.userweb_nama,
                    "kode_agent" : this.item.kode_agent,
                    "status_" : this.item.status_,
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil diupdate', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorEdit', {});
                    // this.item = {};
                    this.editItemDialog = false;
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorEdit', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal diupdate', life: 3000,});
            });
		},
    }
}
</script>